const config = {
  PROJECTX_BASE_URL: "https://demodashboardapi.shipsy.in",
  SCRIPT_AUTOMATION_BASE_URL: "https://internaldashboarddemobackend.shipsy.in",
  GOOGLE_CLIENT_ID: "140812084518-q8bio21cslogv2moehmigj6ssdalp309.apps.googleusercontent.com",
  CT_INTERNAL_BASE_URL: 'https://shipperdashboarddemoapi.shipsy.in',
  EXIM_DASHBOARD_URL : '.staging.shipsy.in',
  SUPPORT_TOOL_BACKEND_URL: 'https://supporttooldemoapi.shipsy.in',
  SUPPORT_TOOL_AllOWED_FILE_TO_UPLOAD: '.jpeg,.pdf,.png,.xls,.xlsx,.docx',
  SHOW_DEV_TOOLS: true,
  JOB_SCHEDULER_BASE_URL: 'https://crondemoapi.shipsy.io',
  ETL_DASHBOARD_BACKEND_URL: 'https://etldemo.shipsy.in',
  SUPPORT_LOGIN_ACCESS_REDIRECT_URL: '.demo.shipsy.io',
  DEAD_LETTER_BASE_URL: 'https://deadletterdemo.shipsy.io',
  LOW_CODE_BASE_URL: 'https://demolowcodeapi.shipsy.io',
    LTL_BASE_URL: 'https://ltlapidemo.shipsy.in',
   CRM_DOMAIN: ".demo.shipsy.io",
  PROJECTX_INTEGRATION_CONFIG: {
     '1': 'https://alphademodashboardapi.shipsy.io'
  },
  USE_DYNAMODB_FOR_ORG_FETCH: false
};
export default config;

